import { PUBLIC_SENTRY_DSN } from "$env/static/public";
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
	environment: import.meta.env.MODE,
	dsn: PUBLIC_SENTRY_DSN,
	tunnel: "/sentry-tunel",
	tracesSampleRate: 1.0,

	replaysSessionSampleRate: import.meta.env.DEV ? 1 : 0.1,
	replaysOnErrorSampleRate: 1.0
	// integrations: [new Replay()]
});

// If wee need a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
